import gql from 'graphql-tag'
import { graphRequestClient } from 'utils/getGraphqlClient'
import { IUser } from '../types'
/**
 * Fetch Projects to list.
 */
export const authUserRequest = async (address: string, signature: string, message: string): Promise<IUser> => {
  try {
    const query = gql`
      query auth($address: String!, $signature: String!, $message: String!) {
        auth(address: $address, signature: $signature, message: $message) {
          code
          message
          jwt
          user {
            _id
            first_name
            last_name
            crypto_address
            solana_address
            crypto_address_verified
            user_kyc_level
            kyc_verified
            role
            email
          }
        }
      }
    `
    const data = await graphRequestClient.request(query, { address, message, signature })
    console.info(data)
    return {
      ...data.auth.user,
      jwt: data.auth.jwt,
    }
  } catch (error) {
    console.error('Failed to fetch top pools', error)
    return null
  }
}
