import { SupportedChainId } from 'config/constants/chains'
import random from 'lodash/random'

// Array of available nodes to connect to
export const nodes = [
  'https://mainnet.infura.io/v3/4e3e9eec0461480e918bb370c32ebcc0',
  'https://mainnet.infura.io/v3/49333d3c0b36439fb20a6ea0a6e99c93',
]

const getNodeUrl = (): string => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl

export const getEndpoint = () => {
  return 'https://atlas.rpcpool.com/'
}

const bscNodes = [
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed1.ninicoin.io',
]
export const getBSCNodeURL = (): string => {
  const randomIndex = random(0, bscNodes.length - 1)
  return bscNodes[randomIndex]
}

const polygonNodes = ['https://rpc-mainnet.maticvigil.com/v1/71b393674f070620b17f5605ca77fe6c9cce093f']
export const getPolygonNodeURL = (): string => {
  const randomIndex = random(0, polygonNodes.length - 1)
  return polygonNodes[randomIndex]
}

export const getGoerliNodeURL = (): string => {
  return 'https://goerli.infura.io/v3/26d9e50165034657bd12c7500f67ff78'
}

export const getFujiNodeURL = (): string => {
  return 'https://api.avax-test.network/ext/bc/C/rpc'
}

export const getBscTestnetNodeURL = (): string => {
  return 'https://data-seed-prebsc-1-s1.binance.org:8545/'
}

export const getAvaxNodeUrl = (): string => {
  return 'https://api.avax.network/ext/bc/C/rpc'
}
export const getFantomNodeUrl = () => {
  return 'https://rpc.ankr.com/fantom_testnet/'
}
export const getMumbaiNodeURL = () => {
  return 'https://matic-mumbai.chainstacklabs.com'
}
export const getArbitrumNodeURL = () => {
  return 'https://arb1.arbitrum.io/rpc'
}
export const getOptimismNodeURL = () => {
  return 'https://mainnet.optimism.io'
}
export const getArbitrumTestnetNodeURL = () => {
  return 'https://goerli-rollup.arbitrum.io/rpc'
}
export const getEVMNodeURL = {
  [SupportedChainId.ETHEREUM]: getNodeUrl(),
  [SupportedChainId.POLYGON]: getPolygonNodeURL(),
  [SupportedChainId.BINANCE]: getBSCNodeURL(),
  [SupportedChainId.FUJI]: getFujiNodeURL(),
  [SupportedChainId.GOERLI]: getGoerliNodeURL(),
  [SupportedChainId.BINANCE_TESTNET]: getBscTestnetNodeURL(),
  [SupportedChainId.FANTOM_TESTNET]: getFantomNodeUrl(),
  [SupportedChainId.MUMBAI]: getMumbaiNodeURL(),
  [SupportedChainId.ARBITRUM]: getArbitrumNodeURL(),
  [SupportedChainId.OPTIMISM]: getOptimismNodeURL(),
  [SupportedChainId.ARBITRUM_TESTNET]: getArbitrumTestnetNodeURL(),
}
