import { ContributionChainListResponse } from 'components/WalletModal/type'
import { SupportedChainId } from 'config/constants/chains'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'
import { graphRequestClient } from 'utils/getGraphqlClient'
import { IContribution } from '../types'
/**
 * Fetch Contribution Data with signature.
 */
export const getContributionSignatureRequest = async (
  projectId: string,
  tokenAddress: string,
  chainId: SupportedChainId,
  amountToContribute: number,
  jwt: string,
): Promise<IContribution> => {
  try {
    const query = gql`
      query getContributionData($projectId: String, $tokenAddress: String, $chainId: Int, $amountToContribute: Int) {
        getContributionData(
          projectId: $projectId
          tokenAddress: $tokenAddress
          chainId: $chainId
          amountToContribute: $amountToContribute
        ) {
          code
          message
          signature
          saleId
          tokenIndex
          contributionAmount
          transactionData
          to
          data
          value
        }
      }
    `
    const data = await graphRequestClient.request(
      query,
      { projectId, tokenAddress, chainId, amountToContribute },
      { Authorization: jwt },
    )
    return data.getContributionData
  } catch (error) {
    console.error('Failed to fetch user status', error)
    return null
  }
}

/**
 * Fetch Contribution Data with signature.
 */
export const getUserContributionLimitRequest = async (
  projectId: string,
  jwt: string,
): Promise<{
  limit: number
  contributed: number
}> => {
  try {
    const query = gql`
      query getUserContributionLimit($projectId: String!) {
        getUserContributionLimit(projectId: $projectId) {
          code
          message
          limit
          contributed
        }
      }
    `
    const data = await graphRequestClient.request(query, { projectId }, { Authorization: jwt })
    return {
      limit: +data.getUserContributionLimit.limit,
      contributed: +data.getUserContributionLimit.contributed,
    }
  } catch (error) {
    console.error('Failed to fetch user status', error)
    return {
      limit: 0,
      contributed: 0,
    }
  }
}

/**
 * Fetch Project List
 */
export const useGetContributionLimit = (projectId: string, jwt: string) => {
  const [contributionLimit, setContributionLimit] = useState(0)
  const [refetchState, setRefetchState] = useState(false)

  const refetch = () => {
    setRefetchState(!refetchState)
  }
  useEffect(() => {
    const fetch = async () => {
      const response = await getUserContributionLimitRequest(projectId, jwt)
      setContributionLimit(response.limit)
    }
    fetch()
  }, [refetchState])

  return { contributionLimit, refetch }
}

export const getContributionChainList = async (): Promise<Array<ContributionChainListResponse>> => {
  try {
    const query = gql`
      query getContributionChainList {
        getContributionChainList {
          name
          chainId
          wormholeChainId
          logoUri
          rpcUrl
        }
      }
    `
    const data = await graphRequestClient.request(query, {}, {})
    return data.getContributionChainList
  } catch (error) {
    console.error('Failed to fetch the chain list', error)
    return null
  }
}
