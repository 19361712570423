import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../../index'
import { toggleTheme as toggleThemeAction, setUser as setUserAction, setUserLoader } from '../actions'
import { IUser } from '../types'

export function useThemeManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>()
  const isDark = useSelector<AppState, AppState['user']['isDark']>((state) => state.user.isDark)

  const toggleTheme = useCallback(() => {
    dispatch(toggleThemeAction())
  }, [dispatch])

  return [isDark, toggleTheme]
}

export function useUserManager(): [IUser, (IUser) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector<AppState, AppState['user']['info']>((state) => state.user.info)

  const setUser = useCallback(
    (_user: IUser) => {
      dispatch(setUserAction(_user))
    },
    [dispatch],
  )

  return [user, setUser]
}

export function useUserActionHandler(): {
  setSignAuthLoading: (isLoading: boolean) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const setSignAuthLoading = useCallback((isLoading) => {
    dispatch(setUserLoader({ authLoading: isLoading }))
  }, [])

  return {
    setSignAuthLoading,
  }
}
