import { SupportedChainId } from 'config/constants/chains'

export interface IWalletInfo {
  chainId: SupportedChainId
}

export interface WalletState {
  wallet: IWalletInfo | null
  isReadyToConnect: WalletStateEnum
  connected: boolean
  selectWallet:string
  selectedChainID:number
  solanaConnected:boolean

}

export enum WalletStateEnum {
  READY = 'READY',
  CONNECTED = 'CONNECTED',
  NOT = 'NOT',
}
