import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { IUser } from './types'

/**
 * Returns Current Logged In User
 * @param field
 */
export function useCurrentUser(): IUser {
  // return null;
  const state = useSelector<AppState, AppState['user']>((s) => s.user)
  return state.info
}

/**
 * Returns the current state of the loader used for sign auth
 * @param field
 */
export function useSignAuthLoader() {
  const state = useSelector<AppState, AppState['user']>((s) => s.user)
  return state.authLoading
}

export function useTotalTokenClaimed(): number {
  const state = useSelector<AppState, AppState['user']>((s) => s.user)
  return state.totalTokenClaimed
}

export function useTokenClaimed(): number {
  const state = useSelector<AppState, AppState['user']>((s) => s.user)
  return state.tokenClaimed
}

export function useRefetch() {
  const state = useSelector<AppState, AppState['user']>((s) => s.user)
  return state.reFetch
}