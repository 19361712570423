import { AlertType } from 'components/Alert'
import { SupportedChainId } from 'config/constants/chains'

export enum WalletStateEnum {
  READY = 'READY',
  CONNECTED = 'CONNECTED',
  NOT = 'NOT',
}

export enum KYC_STATUS {
  UNVALIDATED = 'UNVALIDATED',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  FINAL_REJECTED = 'FINAL_REJECTED',
}
export interface IUser {
  _id: string
  first_name: string
  last_name: string
  email: string
  interestedProjectSubscribe: boolean
  newsletterSubscribe: boolean
  crypto_address: string
  solana_address: string
  user_kyc_level: string
  kyc_verified: string
  jwt: string
  role: string
}

export enum UserStatusOnProject {
  KYC_SUBMITTED = 'kyc_submitted',
  KYC_REJECTED = 'kyc_rejected',
  TOKEN_ALREADY_CLAIMED = 'TOKEN_ALREADY_CLAIMED',
  TOKEN_ALREADY_REFUNDED = 'TOKEN_ALREADY_REFUNDED',
  TOKEN_CLAIM = 'TOKEN_CLAIM',
  NOT_CONTRIBUTED = 'NOT_CONTRIBUTED',
  TOKEN_LOCKED = 'TOKEN_LOCKED',
  NOT_WHITELISTED = 'NOT_WHITELISTED',
  CONTRIBUTED = 'CONTRIBUTED',
  CONTRIBUTE = 'CONTRIBUTE',
  WHITELISTED = 'WHITELISTED',
  MORE_CONTRIBUTION = 'MORE_CONTRIBUTION',
  EXPRESS_INTEREST_SHOWN = 'EXPRESS_INTEREST_SHOWN',
  EXPRESS_INTEREST_NOT_SHOWN = 'EXPRESS_INTEREST_NOT_SHOWN',
  REGISTRATION_NOT_STARTED = 'REGISTRATION_NOT_STARTED',
  REGISTRATION_ENDED = 'REGISTRATION_ENDED',
  REFUND_CLAIM = 'REFUND_CLAIM',
  VESTING_REFUND = 'VESTING_REFUND',
  VESTING_REDEEM = 'VESTING_REDEEM',
  VESTING_ALREADY_REDEEMED = 'VESTING_ALREADY_REDEEMED',
  VESTING_LOCKED = 'VESTING_LOCKED'
}

export interface IClaimData {
  tokenIndex: number
  chainId: SupportedChainId
  excessContribution?: string
  excessContributionTxHash?: string
  claimAllocation?: string
  claimAllocationTxHash?: string
  userVestingTotalTokens?: number
  vestingTokenNextUnlockTime?: Date
  userVestingAvailableTokens?: string
  userVestingAllocation?: string
  userAlreadyClaimedTokens?: string
}
export interface IUserStatusOnProject {
  message: string
  description: string
  status: AlertType
  currentStatus: UserStatusOnProject
  data: IClaimData
  isAction: boolean
}

export interface IKycSessionResponse {
  code: number
  status: KYC_STATUS
  message: string
}

export interface IContribution {
  signature: string
  saleId: string
  tokenIndex: string
  contributionAmount: string
  transactionData: string
  to: string
  data: string
  value: string
  code: number
  message: string
}

export interface IContributionLimit {
  limit: number
  code: number
}

export const SUCCESS_STATUS = new Set([
  UserStatusOnProject.CONTRIBUTED,
  UserStatusOnProject.TOKEN_ALREADY_CLAIMED,
  UserStatusOnProject.TOKEN_ALREADY_REFUNDED,
])

export const API_CALL_STATUS = new Set([
  UserStatusOnProject.CONTRIBUTED,
  UserStatusOnProject.TOKEN_ALREADY_CLAIMED,
  UserStatusOnProject.TOKEN_ALREADY_REFUNDED,
  UserStatusOnProject.TOKEN_CLAIM,
  UserStatusOnProject.REFUND_CLAIM,
  UserStatusOnProject.VESTING_REFUND,
  UserStatusOnProject.VESTING_REDEEM,
  UserStatusOnProject.VESTING_LOCKED,
  UserStatusOnProject.VESTING_ALREADY_REDEEMED,
])

export const HISTORY_SHOWN = new Set([
  UserStatusOnProject.KYC_SUBMITTED,
  UserStatusOnProject.KYC_REJECTED,
  UserStatusOnProject.NOT_CONTRIBUTED,
  UserStatusOnProject.NOT_WHITELISTED,
  UserStatusOnProject.EXPRESS_INTEREST_NOT_SHOWN,
  UserStatusOnProject.REGISTRATION_NOT_STARTED,
  UserStatusOnProject.REGISTRATION_ENDED,
])

export interface IAccountParams {
  email: string
  newsletterSubscribe: boolean
  interestedProjectSubscribe: boolean
}

export const COMPLETED_STATUS = new Set([
  UserStatusOnProject.TOKEN_ALREADY_CLAIMED,
  UserStatusOnProject.TOKEN_ALREADY_REFUNDED,
])
export interface IUserResponse {
  code: number
  message: string
  user: IUser
}

export const VESTING_SET = new Set([
  UserStatusOnProject.VESTING_REFUND,
  UserStatusOnProject.VESTING_REDEEM,
  UserStatusOnProject.VESTING_LOCKED,
])

export const VESTING_REDEEM_LOCKED = new Set([
  UserStatusOnProject.VESTING_REDEEM,
  UserStatusOnProject.VESTING_LOCKED,
  UserStatusOnProject.VESTING_ALREADY_REDEEMED,
])

