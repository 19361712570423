import { ApolloClient, InMemoryCache, split } from '@apollo/client'
import { GraphQLClient } from 'graphql-request'
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {NEXA_API_BASE_URL, NEXA_API_BASE_URL_SUBSCRIPTION} from '../config'

// Extra headers
// Mostly for dev environment
// No production env check since production preview might also need them
// export const getGQLHeaders = () => {
//   const userInfo = null // useCurrentUser()

//   return {
//     authorization: userInfo?.jwt
//   }
// }

export const graphRequestClient = new GraphQLClient(NEXA_API_BASE_URL)

export const getGraphqlClient = () => {
  const userInfo = null // useCurrentUser()

  const httpLink = createUploadLink({
    uri: NEXA_API_BASE_URL,
    headers: {
      authorization: userInfo?.jwt || '',
    },
  })

  const wsLink = new GraphQLWsLink(createClient({
    url: NEXA_API_BASE_URL_SUBSCRIPTION,
  }
  ));

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: splitLink
  })

  return { client }
}
