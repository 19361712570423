import { useWallet } from '@solana/wallet-adapter-react'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { setSolanaConnected, setWalletState, updateWalletStatus } from '../actions'
import { IWalletInfo, WalletStateEnum } from '../types'
import * as Sentry from '@sentry/react'
import { useUserActionHandler, useUserManager } from 'state/user/hooks'
import { authMessage } from 'config/constants'
import { uint8ArrayToHex } from '@certusone/wormhole-sdk'
import { authUserRequest } from 'state/user/queries/authUser'
import { solanaSupportiveChains } from 'views/Project/utils'

export function useWalletStateHandler() {
  const { onWalletStateUpdate, onWalletStatusUpdate } = useWalletActionHandlers()
  const { wallet: solanaWalletAdapter, connect, connected, publicKey, signMessage } = useWallet()
  const stateInfo = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  const dispatch = useDispatch<AppDispatch>()
  const [user, setUser] = useUserManager()
  const { account } = useWeb3React()
  const { setSignAuthLoading } = useUserActionHandler()
  const walletInfo = useWalletState()
  const selectedChainID = getChainID()

  useEffect(() => {
    const connectSolanaWallet = async () => {
      try {
        await connect().catch(() => {
          console.error('Authorization Error', 'Please authorize to access your account')
          throw new Error('Please authorize to access your account')
        })

        const data = new TextEncoder().encode(authMessage)
        const signed = await signMessage(data)
        const signedString = uint8ArrayToHex(signed)

        const _user = await authUserRequest(publicKey.toBase58(), signedString, authMessage)
        setUser(_user)
        if(_user){
          dispatch(setSolanaConnected(true))
        }
        await onWalletStateUpdate(WalletStateEnum.CONNECTED)
        //set wallet state in redux after successfully signIn

        //TODO : chain will be  dynamic with user selection 
        const wallet: IWalletInfo = {
          chainId: selectedChainID,
        }
        await onWalletStatusUpdate(wallet)
        dispatch(setSolanaConnected(false))
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const connectEVM = async (selectedId) => {
      setSignAuthLoading(true)
      try {
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [authMessage, account, 'Random text'],
        })

        const _user = await authUserRequest(account, signature as any as string, authMessage)
        setUser(_user)
        //set wallet state in redux after successfully signIn
        const wallet: IWalletInfo = {
          chainId: selectedId,
        }
        onWalletStatusUpdate(wallet)
        setSignAuthLoading(false)
      } catch (error) {
        setSignAuthLoading(false)
      }
    }
    if (
      account !== undefined &&
      user?.crypto_address.toLowerCase() !== account.toLowerCase() &&
      walletInfo &&
      !solanaSupportiveChains.has(walletInfo.chainId)
    ) {
      connectEVM(walletInfo.chainId)
    }

    const disconnectSolanaWallet = async () => {
      onWalletStateUpdate(WalletStateEnum.NOT)
    }
    stateInfo.isReadyToConnect === WalletStateEnum.READY && connectSolanaWallet()
    !solanaWalletAdapter && stateInfo.isReadyToConnect === WalletStateEnum.CONNECTED && disconnectSolanaWallet()
  }, [solanaWalletAdapter, connected, publicKey, dispatch, stateInfo.isReadyToConnect, account])
} // end of use Wallet State Handler

// Handler to call in our views
export function useWalletActionHandlers(): {
  onWalletStatusUpdate: (walletInfo: IWalletInfo) => void
  getConnectedWalletAddress: () => string | null
  getConnectedWalletAddressFromChainID: (chainId: SupportedChainId) => string | null
  onWalletSingleStatusUpdate: (walletInfo: IWalletInfo) => void
  onWalletStateUpdate: (walletState: WalletStateEnum) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const { account, active } = useWeb3React()
  const stateInfo = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  const { connected, publicKey } = useWallet()

  const getConnectedWalletAddress = useCallback(() => {
    const { wallet } = stateInfo
    let walletAddress = ''
    if (!wallet) {
      // this check is to return null if wallet not connected.
      return null
    }
    if (SupportedEVMChainId(wallet.chainId)) {
      walletAddress = account ? account : null
    } else {
      walletAddress = publicKey ? publicKey.toBase58() : null
    }

    return walletAddress
  }, [account, publicKey, connected, active, stateInfo, dispatch])
  const getConnectedWalletAddressFromChainID = useCallback(
    (chainId) => {
      let walletAddress = ''

      if (SupportedEVMChainId(chainId)) {
        walletAddress = account ? account : null
      } else {
        walletAddress = publicKey ? publicKey.toBase58() : null
      }

      return walletAddress
    },
    [account, publicKey, connected, active, stateInfo, dispatch],
  )
  const onWalletStatusUpdate = useCallback(
    (walletInfo: IWalletInfo) => {
      console.info(' HOOK for update updateWalletStatus', walletInfo)
      dispatch(updateWalletStatus({ walletInfo }))
    },
    [dispatch],
  )

  const onWalletSingleStatusUpdate = useCallback(
    (walletInfo: IWalletInfo) => {
      dispatch(updateWalletStatus({ walletInfo }))
    },
    [dispatch],
  )

  const onWalletStateUpdate = useCallback(
    (walletState: WalletStateEnum) => {
      dispatch(setWalletState({ walletState }))
    },
    [dispatch],
  )

  return {
    onWalletStatusUpdate,
    getConnectedWalletAddress,
    onWalletSingleStatusUpdate,
    onWalletStateUpdate,
    getConnectedWalletAddressFromChainID,
  }
} // end of use Swap Action Handler

/**
 * Returns Wallet of selected Chain
 * @param field
 */
export function useWalletState(): IWalletInfo {
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state.wallet
}

/**
 * Returns Wallet ready state
 * @param field
 */
export function useWalletReadyState(): WalletStateEnum {
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state.isReadyToConnect
}
export function getWalletName():string{
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state.selectWallet
}
export function getChainID():number{
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state.selectedChainID
}
export function getConnectedSolanaStatus():boolean{
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state.solanaConnected
}