import { EVM_WALLETS } from 'config';
import { setSelectedChainId, setSelectedWalletName, setSolanaConnected } from 'state/wallets/actions';
import { createReducer } from '@reduxjs/toolkit'
import { updateWalletStatus, setWalletState } from './actions'
import updateVersion from 'state/global/actions'
import { WalletState, WalletStateEnum } from './types'


const initialWalletState: WalletState = {
  wallet: null,
  isReadyToConnect: WalletStateEnum.NOT,
  connected: false,
  selectWallet:EVM_WALLETS[0].adapter.name,
  selectedChainID:null,
  solanaConnected:false
}

export default createReducer(initialWalletState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialWalletState,
      }
    })
    .addCase(updateWalletStatus, (state, { payload: { walletInfo } }) => {
      console.info('------ INSIDE updateWalletStatus', walletInfo)
      return {
        ...state,
        wallet: walletInfo,
      }
    })
    .addCase(setWalletState, (state, { payload: { walletState } }) => {
      return {
        ...state,
        isReadyToConnect: walletState,
      }
    })
    .addCase(setSelectedWalletName, (state, {payload}) => {
      return {
        ...state,
        selectWallet:payload
      }
    })
    .addCase(setSelectedChainId, (state, {payload}) => {
      return {
        ...state,
        selectedChainID:payload
      }
    })
    .addCase(setSolanaConnected, (state, {payload}) => {
      return {
        ...state,
        solanaConnected:payload
      }
    }),
)
