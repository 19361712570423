// Set of helper functions to facilitate wallet setup

import { SupportedChainId } from 'config/constants/chains'
import { CurrencyAmount } from 'config/entities'
import getNodeUrl, {
  getGoerliNodeURL,
  getFujiNodeURL,
  getAvaxNodeUrl,
  getBSCNodeURL,
  getPolygonNodeURL,
  getBscTestnetNodeURL,
  getFantomNodeUrl,
  getMumbaiNodeURL,
  getArbitrumNodeURL,
  getOptimismNodeURL,
  getArbitrumTestnetNodeURL,
} from './getRpcUrl'

const Networks = [
  {
    name: 'FANTOM Mainnet',
    chainId: SupportedChainId.FANTOM,
    eventKey: 'FTM',
    minimumFee: new CurrencyAmount(0.2, 18, false),
    decimals: 18,
    nativeCurrency: {
      name: 'FANTOM',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ftm.tools/'],
    blockExplorerUrls: ['https://ftmscan.com/'],
  },
  {
    name: 'Ethereum',
    chainId: SupportedChainId.ETHEREUM,
    eventKey: 'ETH',
    minimumFee: new CurrencyAmount(0.01, 18, false),
    decimals: 18,
    rpcUrls: [getNodeUrl()],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
  },
  {
    name: 'Ethereum',
    chainId: SupportedChainId.GOERLI,
    eventKey: 'ETH',
    minimumFee: new CurrencyAmount(0.01, 18, false),
    decimals: 18,
    rpcUrls: [getGoerliNodeURL()],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
  },
  {
    name: 'Solana',
    chainId: SupportedChainId.SOLANA,
    eventKey: 'SOL',
    minimumFee: new CurrencyAmount(0.005, 9, false),
    decimals: 9,
    nativeCurrency: {
      name: 'SOL',
      symbol: 'sol',
      decimals: 9,
    },
  },

  {
    name: 'Binance',
    chainId: SupportedChainId.BINANCE,
    eventKey: 'BNB',
    minimumFee: new CurrencyAmount(0.006, 18, false),
    decimals: 18,
    rpcUrls: [getBSCNodeURL()],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
  },
  {
    name: 'Avalanche',
    chainId: SupportedChainId.AVAX,
    eventKey: 'AVAX',
    minimumFee: new CurrencyAmount(0.03, 18, false),
    decimals: 18,
    rpcUrls: [getAvaxNodeUrl()],
    nativeCurrency: {
      name: 'Avax',
      symbol: 'avax',
      decimals: 18,
    },
  },
  {
    name: 'Avalanche',
    chainId: SupportedChainId.FUJI,
    eventKey: 'AVAX',
    minimumFee: new CurrencyAmount(0.03, 18, false),
    decimals: 18,
    rpcUrls: [getFujiNodeURL()],
    nativeCurrency: {
      name: 'Avax',
      symbol: 'avax',
      decimals: 18,
    },
  },
  {
    name: 'Polygon',
    chainId: SupportedChainId.POLYGON,
    eventKey: 'MATIC',
    minimumFee: new CurrencyAmount(0.2, 18, false),
    decimals: 18,
    rpcUrls: [getPolygonNodeURL()],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
  },
  {
    name: 'Mumbai',
    chainId: SupportedChainId.MUMBAI,
    eventKey: 'MATIC',
    minimumFee: new CurrencyAmount(0.2, 18, false),
    decimals: 18,
    rpcUrls: [getMumbaiNodeURL()],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'matic',
      decimals: 18,
    },
  },
  {
    name: 'Optimism',
    chainId: SupportedChainId.OPTIMISM,
    eventKey: 'ETH',
    minimumFee: new CurrencyAmount(0.0001, 18, false),
    decimals: 18,
    rpcUrls: [getOptimismNodeURL()],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
  },
  {
    name: 'Fantom testnet',
    chainId: SupportedChainId.FANTOM_TESTNET,
    eventKey: 'FTM',
    minimumFee: new CurrencyAmount(0.2, 18, false),
    decimals: 18,
    rpcUrls: [getFantomNodeUrl()],
    nativeCurrency: {
      name: 'FTM',
      symbol: 'ftm',
      decimals: 18,
    },
  },
  {
    name: 'Binance Testnet',
    chainId: SupportedChainId.BINANCE_TESTNET,
    eventKey: 'BNB',
    minimumFee: new CurrencyAmount(0.006, 18, false),
    decimals: 18,
    rpcUrls: [getBscTestnetNodeURL()],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
  },
  {
    name: 'Arbitrum',
    chainId: SupportedChainId.ARBITRUM,
    eventKey: 'ETH',
    minimumFee: new CurrencyAmount(0.01, 18, false),
    decimals: 18,
    rpcUrls: [getArbitrumNodeURL()],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
  },
  {
    name: 'Arbitrum Testnet',
    chainId: SupportedChainId.ARBITRUM_TESTNET,
    eventKey: 'AETH',
    minimumFee: new CurrencyAmount(0.01, 18, false),
    decimals: 18,
    rpcUrls: [getArbitrumTestnetNodeURL()],
    nativeCurrency: {
      name: 'AETH',
      symbol: 'aeth',
      decimals: 18,
    },
  },
]
/**
 * Prompt the user to add BSC nad etherum as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainID) => {
  const provider = (window as WindowChain).ethereum
  const networkDetail = await fetchNetworkDetail(chainID) // network detail from dataUrl.ts file

  if (networkDetail !== undefined) {
    if (provider) {
      const chainId = parseInt(chainID as string, 10)
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        })
        return true
      } catch (switchError: any) {
        if (switchError?.code === 4001) {
          throw new Error(switchError.message)
        }

        if (switchError?.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${chainId.toString(16)}`,
                  chainName: networkDetail.name,
                  nativeCurrency: networkDetail.nativeCurrency,
                  rpcUrls: networkDetail.rpcUrls,
                  blockExplorerUrls: networkDetail.blockExplorerUrls,
                },
              ],
            })
            return true
          } catch (error: any) {
            console.error(error)
            throw new Error(error?.message)
          }
        }
        // handle other "switch" errors
      }
    } else {
      console.error("Can't setup the network on metamask because window.ethereum is undefined")
      return false
    }
  } else {
    return false
  }
}

export const fetchNetworkDetail = (chainID) => {
  let selectedNetwork
  Networks.map((item) => {
    if (item.chainId === chainID) {
      selectedNetwork = item
    }
    return item
  })
  return selectedNetwork
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string,
) => {
  const tokenAdded = await (window as Window).ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  })

  return tokenAdded
}

export const isSameNetwork = async (chainId) => {
  const provider = window.ethereum
  if (provider) {
    const selectedChainId = await provider.request({
      method: 'eth_chainId',
    })
    // @ts-ignore
    return selectedChainId === `0x${chainId.toString(16)}`
  }
}

export const getMetaMaskNetwork = async () => {
  const provider = window.ethereum
  return await provider.request({
    method: 'eth_chainId',
  })
}

export const changeNetwork = async (chainId = 1): Promise<boolean> => {
  const provider = window.ethereum
  if (provider) {
    try {
      const isSame = await isSameNetwork(chainId)
      if (isSame) {
        return true
      }
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      return true
    } catch (error) {
      //chain unrecognized adding new chain
      if (error['code'] === 4902) {
        return await addNewNetwork(chainId)
      } else if (error['code'] === 4001) {
        return false
      }
    }
    return false
  }
}

const addNewNetwork = async (chainId): Promise<boolean> => {
  const provider = window.ethereum
  const networkDetail = await fetchNetworkDetail(chainId) // network detail from dataUrl.ts file
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: networkDetail.name,
            nativeCurrency: networkDetail.nativeCurrency,
            rpcUrls: networkDetail.rpcUrls,
            blockExplorerUrls: networkDetail.blockExplorerUrls,
          },
        ],
      })
      const selectedChainId = await provider.request({
        method: 'eth_chainId',
      })
      // @ts-ignore
      if (selectedChainId === `0x${chainId.toString(16)}`) {
        return true
      } else {
        return false
      }
    } catch (error) {
      if (error['code'] === 4001) {
        return false
      }
    }
  }
  return false
}
