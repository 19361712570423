import { createReducer } from '@reduxjs/toolkit'
import { SupportedChainId } from 'config/constants/chains'
import { updateVersion } from '../global/actions'
import { toggleTheme, setUser, setUserLoader, setTotalTokenClaimed, setTokenClaimed, setRefetch } from './actions'
import { IUser } from './types'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number
  timestamp: number
  isDark: boolean
  info: IUser
  connectedWalletChainId: SupportedChainId
  authLoading: boolean
  totalTokenClaimed: number
  tokenClaimed: number
  reFetch: boolean
}

export const initialState: UserState = {
  timestamp: currentTimestamp(),
  isDark: false,
  connectedWalletChainId: -1,
  info: null,
  authLoading: false,
  totalTokenClaimed: 0,
  tokenClaimed: 0,
  reFetch: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setUser, (state, { payload }) => {
      state.info = payload
    })
    .addCase(updateVersion, (state) => {
      // noinspection SuspiciousTypeOfGuard
      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(toggleTheme, (state) => {
      state.isDark = !state.isDark
    })
    .addCase(setUserLoader, (state, action) => {
      state.authLoading = action.payload.authLoading
    })
    .addCase(setTotalTokenClaimed, (state, action) => {
      state.totalTokenClaimed = action.payload.totalTokenClaimed
    })
    .addCase(setTokenClaimed, (state, action) => {
      state.tokenClaimed = action.payload.tokenClaimed
    })
    .addCase(setRefetch, (state, action) => {
      state.reFetch = action.payload.reFetch
    })
)
